<template>
  <LineChart :chart-options="chartOptions" :chart-data="chartData" />
</template>

<script>
import { Line as LineChart } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  Filler,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  Filler,
  PointElement
)

export default {
  name: 'LineChartAtom',
  components: {
    LineChart
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({})
    },
    chartOptions: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
