<template>
  <LineChart :chart-data="chartData" :chart-options="chartOptions" />
</template>

<script>
import LineChart from '@/components/atoms/LineChart.vue'

export default {
  name: 'OrdersLandingsLineChart',
  components: {
    LineChart,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.data?.map((e) => e.date),
        datasets: this.datasets,
      }
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          tooltip: {
            callbacks: {
              footer: (tooltipItems) => {
                const sum = tooltipItems?.reduce(
                  (acc, e) => (acc += e?.parsed?.y),
                  0,
                )
                return `Процентное соотношение к общей выручке лендингов:\n${this.countProcents(
                  sum,
                  this.totalCount,
                )}
                
                `
              },
            },
          },
        },
        scales: {
          y: {
            ticks: {
              callback: (val) => (val > 0 ? `${val} ₽` : val),
            },
          },
        },
      }
    },
  },
  methods: {
    countProcents(sum, total) {
      return ((sum / total) * 100).toFixed(1)
    },
  },
}
</script>
