<template>
  <LineChart :chart-data="chartData" :chart-options="chartOptions" />
</template>

<script>
import { ANALYTICS_COLORS, ANALYTICS_BG_COLORS } from '@/constants/analytics'
import { PROJECTS } from '@/constants/projects'
import LineChart from '@/components/atoms/LineChart.vue'

export default {
  name: 'OrdersLineChart',
  components: {
    LineChart,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.data?.map((e) => e.date),
        datasets: [
          {
            label: 'Addwine',
            borderColor: ANALYTICS_COLORS.ADDWINE,
            backgroundColor: ANALYTICS_BG_COLORS.ADDWINE,
            data: this.data,
            parsing: {
              yAxisKey: PROJECTS.ADDWINE,
              xAxisKey: 'date',
            },
            fill: true,
          },
          {
            label: 'Landings',
            borderColor: ANALYTICS_COLORS.LANDING,
            backgroundColor: ANALYTICS_BG_COLORS.LANDING,
            data: this.data,
            parsing: {
              yAxisKey: PROJECTS.ADDLANDINGS,
              xAxisKey: 'date',
            },
            fill: true,
          },
        ],
      }
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          tooltip: {
            callbacks: {
              footer: (tooltipItems) => {
                const sum = tooltipItems?.reduce(
                  (acc, e) => (acc += e?.parsed?.y),
                  0,
                )
                return `Процентное соотношение к общей выручке:\n${this.countProcents(
                  sum,
                  this.totalCount,
                )}%\nПроцентное соотношение по проектам:\nAddwine: ${this.countProcents(
                  tooltipItems[0]?.parsed?.y,
                  sum,
                )}%\nLanding: ${this.countProcents(
                  tooltipItems[1]?.parsed?.y,
                  sum,
                )}%`
              },
            },
          },
        },
        scales: {
          y: {
            ticks: {
              callback: (val) => (val > 0 ? `${val} ₽` : val),
            },
          },
        },
      }
    },
  },
  methods: {
    countProcents(sum, total) {
      return ((sum / total) * 100).toFixed(1)
    },
  },
}
</script>
