export const roundingPrice = (price) => {
  if (!price) return 0
  if (!Number.isInteger(price)) {
    return price.toLocaleString('ru-RU', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  return price.toLocaleString()
}
