<template>
  <section :class="$style.analytics">
    <div :class="$style.ordersSection">
      <p :class="$style.title">Заказы</p>
      <p :class="$style.title">
        Общая выручка: {{ roundingPrice(totalCount) }} ₽
      </p>
      <div :class="$style.dateRange">
        с
        <el-date-picker
          v-model="from"
          type="date"
          placeholder="Выберите дату"
          :class="$style.date"
          @change="getAnalytics"
        >
        </el-date-picker>
        по
        <el-date-picker
          v-model="to"
          type="date"
          placeholder="Выберите дату"
          :class="$style.date"
          @change="getAnalytics"
        >
        </el-date-picker>
      </div>
      <OrdersLineChart
        :data="data"
        :total-count="totalCount"
        :class="$style.chart"
      />

      <p :class="$style.title">
        Общая выручка лендинги: {{ roundingPrice(totalCountLandings) }} ₽
      </p>

      <div :class="$style.dateRange">
        с
        <el-date-picker
          v-model="landingsFrom"
          type="date"
          placeholder="Выберите дату"
          :class="$style.date"
          @change="getAnalyticsLandings"
        >
        </el-date-picker>
        по
        <el-date-picker
          v-model="landingsTo"
          type="date"
          placeholder="Выберите дату"
          :class="$style.date"
          @change="getAnalyticsLandings"
        >
        </el-date-picker>
      </div>

      <OrdersLandingsLineChart
        :data="landingsData"
        :total-count="totalCountLandings"
        :datasets="landingDataset"
      />
    </div>
  </section>
</template>

<script>
import delivery from '@/delivery'
import { formatDate } from '@/helpers/index.js'
import { roundingPrice } from '@/helpers/productPrice.js'
import { generateRandomRGB } from '@/helpers/addwine/colors.js'
import OrdersLineChart from '@/components/moleculs/OrdersLineChart.vue'
import OrdersLandingsLineChart from '@/components/moleculs/OrdersLandingsLineChart.vue'
import regexp from '@/helpers/regexp.js'
export default {
  components: {
    OrdersLineChart,
    OrdersLandingsLineChart,
  },
  data() {
    return {
      data: [],
      landingsData: [],
      landingDataset: [],
      from: new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date(),
      landingsFrom: new Date(new Date().setDate(new Date().getDate() - 7)),
      landingsTo: new Date(),
    }
  },
  created() {
    this.getAnalytics()
    this.getAnalyticsLandings()
  },
  computed: {
    totalCount() {
      return (
        this.data?.reduce(
          (acc, curr) => (acc += curr.addwine + curr.landings),
          0,
        ) ?? 0
      )
    },
    totalCountLandings() {
      return (
        this.landingsData?.reduce((acc, curr) => {
          curr.landings.forEach((x) => (acc += x.value))
          return acc
        }, 0) ?? 0
      )
    },
  },
  methods: {
    addDay(date) {
      return new Date(new Date(date).setDate(new Date(date).getDate() + 1))
    },
    async getAnalytics(datePickerValue) {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        from: this.from,
        to: this.to,
      }

      if (datePickerValue) {
        query.from = this.addDay(query.from)
        query.to = this.addDay(query.to)
      }

      const { value, error } =
        await delivery.PaymentServiceCore.AnalyticsActions.getOrdersAnalytics(
          query,
        )
      loading.close()

      if (error) return

      this.data =
        value.data?.map((e) => ({
          ...e,
          date: this.formatDate(e.date),
        })) ?? []
    },

    async getAnalyticsLandings(datePickerValue) {
      this.landingsData = []
      this.landingDataset = []

      const loading = this.$loading({
        lock: true,
      })

      const query = {
        from: this.landingsFrom,
        to: this.landingsTo,
      }

      if (datePickerValue) {
        query.from = this.addDay(query.from)
        query.to = this.addDay(query.to)
      }

      const { value, error } =
        await delivery.PaymentServiceCore.AnalyticsActions.getOrdersAnalyticsLandings(
          query,
        )
      loading.close()

      if (error) return

      const datasetUnique = new Set()

      this.landingsData = value?.data?.map((e) => ({
        ...e,
        date: this.formatDate(e.date),
      }))

      const landingsFormattedData = this.landingsData?.map((e) => {
        e.landings.forEach((x) =>
          datasetUnique.add(x.landingName.replaceAll(regexp.landingUrl, '')),
        )

        return {
          date: e.date,
          ...e.landings?.reduce(
            (object, landing) => ({
              ...object,
              [landing.landingName.replaceAll(regexp.landingUrl, '')]:
                landing.value,
            }),
            {},
          ),
        }
      })

      const datasetArray = Array.from(datasetUnique)

      datasetArray.forEach((elem) => {
        const arr = landingsFormattedData?.map((landings) => landings[elem] | 0)
        const { color, r, g, b } = generateRandomRGB()
        const alpha = 0.5

        this.landingDataset.push({
          label: elem,
          borderColor: color,
          backgroundColor: `rgba(${r}, ${g}, ${b}, ${alpha})`,
          data: arr,
          parsing: {
            yAxisKey: elem,
            xAxisKey: 'date',
          },
          fill: true,
        })
      })
    },
    formatDate(date) {
      return formatDate(date)
    },
    roundingPrice(price) {
      return roundingPrice(price)
    },
  },
}
</script>

<style lang="scss" module>
.analytics {
  padding: 1rem;
  .ordersSection {
    .title {
      @include H100;
      margin-bottom: 1rem;
      text-align: center;
    }
    .dateRange {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .chart {
      margin-bottom: 2rem;
    }
  }
}
</style>
